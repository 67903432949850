<template>
<b-card class="mb-3" style="min-width: 20rem;" no-body>

  <template #header>
    <h4>{{ fullName(member) }}</h4>
    <RankImage v-if="member.rank" :rank="member.rank"/>
  </template>

  <b-card-body v-if="member.signups && member.signups.includes('vt-2025') || member.period === 'vt-2025'">
    <p>
      <font-awesome-icon :icon="['fas', 'check-square']" style="color:green"></font-awesome-icon>
      Anmäld till vt-2025.
    </p>
    Terminens avgifter:
    <ul>
      <li>
        <font-awesome-icon v-if="paidMembership" :icon="['fas', 'check-square']" style="color:green"></font-awesome-icon>
        <em> Medlemsavgift 2025: 200 kr</em>
      </li>
      <li>
        <font-awesome-icon v-if="paidWorkout" :icon="['fas', 'check-square']" style="color:green"></font-awesome-icon>
        <em> Träningsavgift vt-2025<span v-if="member.functions && member.functions.length"> ({{ member.functions.join(',') }})</span>: {{ workoutFee(member) }} kr</em>
      </li>
    </ul>
    <em v-if="allFeesPaid">Terminens avgifter är betalda.</em>
    <em v-else-if="paidMembership">Terminens träningsavgift är ej registrerad.</em>
    <em v-else-if="paidWorkout">Årets medlemsavgift är ej registrerad.</em>
    <em v-else>Ingen avgift är registrerad.</em>
    <b-alert variant="primary" :show="!allFeesPaid">
      <strong>Totalt att betala: {{ remainingToPay }} kr</strong><br/>
      till bankgiro <strong>5915-7222</strong><br/>
      senast den <strong>{{ lastPaymentDate(member) }}</strong>.<br/>
      Märk betalningen med "{{ fullName(member) }}".
    </b-alert>
  </b-card-body>

  <b-card-body v-else-if="member.period === 'ht-2025'">
       <p>
       <font-awesome-icon :icon="['fas', 'check-square']" style="color:green"></font-awesome-icon>
       Anmäld till ht-2025.
       </p>
  </b-card-body>

  <b-card-body v-else>
    <p>
      Ännu inte anmäld till vårterminen 2025.
    </p>
    <b-button variant="primary" @click="signupMember(member, 'vt-2025')" :disabled="signingUp">Anmäl {{member.firstName }} till vt-2025</b-button>
  </b-card-body>
</b-card>
</template>

<script>
  import { mapState } from 'vuex';
  import { functions } from '@/firebaseConfig.js'
  import { yearsBetweenDates } from '@/utils/date.js'
  import RankImage from '@/components/RankImage.vue'


  export default {
    props: {
      member: Object
    },
    components: { RankImage },
    data() {
      return {
        signingUp: false,
        semester: 'vt-2025',
        year: '2025',
        semesterStartDate: new Date('2025-01-08')
      }
    },
    computed: {
      ...mapState('user', ['user']),
      paidMembership() {
        return this.member.fees
          && this.member.fees.find(f => f.type === 'membership' && (f.period === this.year || f.year == this.year))
      },
      paidWorkout() {
        return this.member.fees && this.member.fees.find(f => f.type.startsWith('workout-') && f.period === this.semester)
      },
      remainingToPay() {
        let remaining = 0;
        if (!this.paidMembership) {
          remaining += 200;
        }
        if (!this.paidWorkout) {
          remaining += this.workoutFee(this.member);
        }
        return remaining;
      },
      allFeesPaid() {
        return this.paidMembership && this.paidWorkout
      }
    },
    methods: {
      fullName(member) {
        return `${member.firstName} ${member.lastName}`
      },
      birthdate(member) {
        const birthdate = member.birthdate ? member.birthdate : member.personalIdNumber.slice(0, 8)
        return new Date(birthdate.slice(0, 4) + "-" + birthdate.slice(4, 6) + "-" + birthdate.slice(6, 8))
      },
      ageAt(targetDate, member) {
        return yearsBetweenDates(this.birthdate(member), targetDate)
      },
      memberHasFunction(member, funct) {
        return member && member.functions && member.functions.includes(funct)
      },
      workoutFee(member) {
        if (this.memberHasFunction(member, 'styr') || this.memberHasFunction(member, 'kordf')) {
          return 400
        } else if (this.memberHasFunction(member, 'inst')) {
          return 550
        } else if (member.rank && member.rank > 7) {
            return 1000
        } else {
          const ageAtStartDate = this.ageAt(this.semesterStartDate, member)
          return ageAtStartDate < 16 ? 700 : 1000
        }
      },
      lastPaymentDate(member) {
        if (member.isMember && member.firstSignup !== 'vt-2025') {
          // Existing members
          return '8:e januari'
        } else {
          // New members
          if (this.ageAt(this.semesterStartDate, member) < 13) {
            // Beginner kids
            return '21:a januari'
          }
          // Beginner youth and adult
          return '31:a januari'
        }
      },
      async signupMember(member, period) {
        this.signingUp = true
        await functions.httpsCallable('signupMember')({ memberId: member.id, period })
        this.signingUp = false
      }
    }
  }
</script>
